import React, { Component } from 'react';
import { Col } from 'reactstrap';
import axios from 'axios';
import i18n from '../../../../i18next';

import { SERVER_URL, FRONT_END_URL, AUTHIPAY_GATEWAY_LINK, ARABIC } from '../../../../utils/Constants';
import { getAllUrlParams, getQueryInfoFromUrl } from '../../../../utils/CommonUtils';
import { parentLocalStorage, sendMessageToParentSite } from '../../../../utils/CrossOriginUtils';

class AuthipayForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saveCard: false,
            cardId: null,
            storeId: null,
            hash: null,
            timeZone: null,
            textDate: null,
            orderId: null,
            orderStatus: null,
            parentURI: null
        }

        this.autipayFormRef = React.createRef();
        this.changeState = this.changeState.bind(this);
        this.doPlaceOrder = this.doPlaceOrder.bind(this);
    }

    authipayForm = null;

    componentDidMount() {
        let orderStatus = new URLSearchParams(window.location.search).get('status');
        const { shoppingCart, paymentProvider } = this.props;
        const shoppingCartTotalWithGiftCard = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.total : null;

        if (orderStatus) {
            this.props.processingPayment();
        }

        if (orderStatus === 'SUCCESS') {
            this.setState({
                ...this.state,
                orderStatus: orderStatus
            }, () => {
                let providerData = {
                    amount: shoppingCartTotalWithGiftCard ? shoppingCartTotalWithGiftCard.toFixed(2) : shoppingCart.total,
                    transactionId: paymentProvider.authipayData.orderId
                }

                if (paymentProvider.authipayData.cardId) {
                    providerData.tokenId = paymentProvider.authipayData.cardId;
                }
                this.props.dispatchTransactionId(paymentProvider.authipayData.orderId, 4, providerData);

                if (!this.props.pwaAppRunning) {
                    let url = new URL(window.location.href);
                    url.searchParams.delete('status');
                    window.history.replaceState({}, document.title, url);
                }
            });
        } else if (orderStatus === 'FAIL') {
            this.setState({
                ...this.state,
                orderStatus: orderStatus
            },
                this.props.dispatchTransactionId(paymentProvider.authipayData.orderId, 4));

                if (!this.props.pwaAppRunning) {
                    let url = new URL(window.location.href);
                    url.searchParams.delete('status');
                    window.history.replaceState({}, document.title, url);
                }

        } else if (!orderStatus && !this.props.authipayBackBtnFlag) {
            this.props.processingPayment();

            let providerData = {
                amount: shoppingCartTotalWithGiftCard ? shoppingCartTotalWithGiftCard.toFixed(2) : shoppingCart.total,
                transactionId: paymentProvider.authipayData.orderId
            }

            if (paymentProvider.authipayData.cardId) {
                providerData.tokenId = paymentProvider.authipayData.cardId;
            }
            // Last param is for time out interval and indicates the number of seconds
            this.props.dispatchTransactionId(paymentProvider.authipayData.orderId, 4, providerData, 30);
        }

        const isIntegration = getQueryInfoFromUrl().brandId;

        if (isIntegration) {
            setTimeout(() => {         
                parentLocalStorage('get', 'parentUrl')
                .then(parentUrl => {
                    this.setState({
                        ...this.state,
                        parentURI: parentUrl
                    })
                });
            }, 2000);
        }
    }

    componentDidUpdate(prevProps) {
        const { payment, paymentProvider, shoppingCart } = this.props;
        const shoppingCartTotalWithGiftCard = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.total : null;
        if (payment.selectedPaymentType.id === 1 && !payment.isCardValid) {
            this.props.validateCardInput(true);
        }

        if (paymentProvider.authipayData.orderId !== prevProps.paymentProvider.authipayData.orderId && paymentProvider.authipayData.orderId !== null) {
            setTimeout(() => {
                if (!this.props.pwaAppRunning) {
                    this.authipayForm.submit();
                    this.props.changeAuthipayBackBtnFlag(null);
                } else {
                    const pageContent = `<html><body>${this.authipayForm.outerHTML}<script type="text/javascript">document.getElementById("authipayForm").submit();</script></body></html>`;
                    const pageContentUrl = 'data:text/html;base64,' + btoa(pageContent);
                    // let browserRef = window.cordova.InAppBrowser.open(pageContentUrl, '_self', 'location=no');
                    let browserRef = window.cordova.InAppBrowser.open(pageContentUrl, '_blank', 'location=no,toolbar=no'); // iOS only works with target = _blank
                    const that = this;
                    let paymentCompleted = null;

                    browserRef.addEventListener('loadstart', function (event) {
                        if ((event.url).endsWith('checkout')) {
                            paymentCompleted = true;
                            let orderStatus = getAllUrlParams(event.url).status;

                            if (orderStatus === 'SUCCESS') {
                                let providerData = {
                                    amount: shoppingCartTotalWithGiftCard ? shoppingCartTotalWithGiftCard.toFixed(2) : shoppingCart.total,
                                    transactionId: paymentProvider.authipayData.orderId
                                }

                                if (paymentProvider.authipayData.cardId) {
                                    providerData.tokenId = paymentProvider.authipayData.cardId;
                                }

                                that.props.dispatchTransactionId(paymentProvider.authipayData.orderId, 4, providerData);
                            } else if (orderStatus === 'FAIL') {
                                that.props.dispatchTransactionId(paymentProvider.authipayData.orderId, 4)
                            } else if (!orderStatus && !this.props.authipayBackBtnFlag) {
                                that.props.processingPayment();

                                let providerData = {
                                    amount: shoppingCartTotalWithGiftCard ? shoppingCartTotalWithGiftCard.toFixed(2) : shoppingCart.total,
                                    transactionId: paymentProvider.authipayData.orderId
                                }

                                if (paymentProvider.authipayData.cardId) {
                                    providerData.tokenId = paymentProvider.authipayData.cardId;
                                }
                                // Last param is for time out interval and indicates the number of seconds
                                that.props.dispatchTransactionId(paymentProvider.authipayData.orderId, 4, providerData, 30);
                            }

                            browserRef.close();
                        }
                    });

                    browserRef.addEventListener('exit', function (event) {

                        if (!paymentCompleted) {
                            that.props.processingPayment();

                                let providerData = {
                                    amount: shoppingCartTotalWithGiftCard ? shoppingCartTotalWithGiftCard.toFixed(2) : shoppingCart.total,
                                    transactionId: paymentProvider.authipayData.orderId
                                }

                                if (paymentProvider.authipayData.cardId) {
                                    providerData.tokenId = paymentProvider.authipayData.cardId;
                                }
                                // Last param is for time out interval and indicates the number of seconds
                                that.props.dispatchTransactionId(paymentProvider.authipayData.orderId, 4, providerData, 30);
                        }
                    });
                }

            }, 2000);
        }

        if (payment.selectedPaymentCard.paymentCardId !== prevProps.payment.selectedPaymentCard.paymentCardId
            && payment.selectedPaymentCard.paymentCardId) {
            this.setState({
                ...this.state,
                saveCard: false
            });
        }
    }

    componentWillMount = () => {
        const isIntegration = getQueryInfoFromUrl().brandId;

        if (isIntegration) {
            sendMessageToParentSite('getParentUrl');
        }
    }

    changeState({ target }) {
        this.setState({
            ...this.state,
            saveCard: !this.state.saveCard
        });
    }

    transformCurrencyIntoCode(currency) {
        switch (currency) {
            case 'cad':
                return 124;
            case 'usd':
                return 840;
            case 'gbp':
                return 826;
            case 'aud':
                return 36;
            case 'bgn':
            case 'eur':
            default:
                return 978; // EUR
        }
    }

    async doPlaceOrder(e) {
        const { customer, selectedRestaurant, shoppingCart, payment } = this.props;
        const shoppingCartTotalWithGiftCard = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.total : null;
        // Prevents the form from refreshing the page
        e.preventDefault();
        // Starts the processing window
        this.props.processingPayment();
        let form = this.autipayFormRef.current;

        try {
            const header = { headers: { 'Authorization': `TOKEN${customer.token}` } };
            const reqObj = {
                customerId: customer.id,
                restaurantId: selectedRestaurant.restaurantId,
                currency: selectedRestaurant.currency,
                amount: shoppingCartTotalWithGiftCard ? shoppingCartTotalWithGiftCard.toFixed(2) : shoppingCart.total.toFixed(2),
                authipayAuthorizeRequest: {
                    saveCard: payment.selectedPaymentCard.paymentCardId ? false : this.state.saveCard // Hardcoded for now should be taken from state
                }
            }

            let response = await axios.post(`${SERVER_URL}/payment/authorize`, reqObj, header);

            this.setState({
                ...this.state,
                storeId: response.data.authipayResponse.storeName,
                hash: response.data.authipayResponse.hash,
                timeZone: response.data.authipayResponse.timezone,
                textDate: response.data.authipayResponse.textDate,
                orderId: response.data.authipayResponse.orderId,
                cardId: response.data.authipayResponse.cardId ? response.data.authipayResponse.cardId : null
            }, () => {
                form.method = 'post';
                form.action = AUTHIPAY_GATEWAY_LINK;

                this.props.saveAuthipayData({
                    storeId: response.data.authipayResponse.storeName,
                    hash: response.data.authipayResponse.hash,
                    timeZone: response.data.authipayResponse.timezone,
                    textDate: response.data.authipayResponse.textDate,
                    orderId: response.data.authipayResponse.orderId,
                    cardId: response.data.authipayResponse.cardId ? this.state.cardId : null
                });
                this.authipayForm = form;
            });
        } catch (error) {
            this.props.errorProcessingPayment(error, 'AuthorizeFailure', 4);
            this.props.sendEmailReport(selectedRestaurant.restaurantId, null, 'Authipay authorize error');
        }
    }

    renderForm() {
        const { id, name } = this.props.customer;
        const { displayAddress, currency } = this.props.selectedRestaurant;
        const { total } = this.props.shoppingCart;
        const shoppingCartTotalWithGiftCard = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.total : null;
        const { navigationType, brandId } = this.props.storeLocalStorage;
        const currentTotal = shoppingCartTotalWithGiftCard ? shoppingCartTotalWithGiftCard : total;

        let successLink = '';
        let failLink = '';

        const isIntegration = getQueryInfoFromUrl().brandId;

        if (isIntegration) {
            successLink = `${FRONT_END_URL}/?brandId=${brandId}&navigationType=${navigationType}&status=SUCCESS#/checkout`;
            failLink = `${FRONT_END_URL}/?brandId=${brandId}&navigationType=${navigationType}&status=FAIL#/checkout`;
        } else {
            successLink = `${FRONT_END_URL}/?status=SUCCESS#/checkout`;
            failLink = `${FRONT_END_URL}/?status=FAIL#/checkout`;
        }

        return (
            <form
                id='authipayForm'
                ref={this.autipayFormRef}
                onSubmit={(e) => this.doPlaceOrder(e)}
            >
                <input type='hidden' name='txntype' value='sale' />
                <input type='hidden' name="timezone" value={this.state.timeZone} />
                <input type='hidden' name='txndatetime' value={this.state.textDate} />
                <input type='hidden' name='hash_algorithm' value='SHA256' />
                <input type='hidden' name='hash' value={this.state.hash} />
                <input type='hidden' name='storename' value={this.state.storeId} />
                <input type='hidden' name='checkoutoption' value='combinedpage' />
                <input type='hidden' name='responseSuccessURL' value={successLink} />
                <input type='hidden' name='responseFailURL' value={failLink} />
                {/* OPTIONAL - This is used for Server-to-Server Notification */}
                <input type="hidden" name="transactionNotificationURL" value={`${SERVER_URL}/payment-status/authipay`} />
                {/* DATA VAULT feature (next two lines) - for saving cards  */}
                {/* <input type='hidden' name='assignToken' value='true' /> COMMENT this is to be send only the first time when card is to be saved */}
                {
                    this.state.cardId || this.props.payment.selectedPaymentCard.paymentCardId ?
                        <input type='hidden' name='hosteddataid' value={this.state.cardId || this.props.payment.selectedPaymentCard.paymentCardId} />
                        : null
                }
                {/* <input type="hidden" name="unscheduledCredentialOnFileType" value="MERCHANT_INITIATED" /> */}
                {/* OPTIONAL - max 48char */}
                {/* <input type="hidden" name="invoicenumber" value="4829-1749-2810" /> */}
                {/* OPTIONAL - if we want to send our orderId otherwise is provided by their database */}
                <input type='hidden' name='oid' value={this.state.orderId} />

                <input type='hidden' name='bname' value={name} />
                <input type='hidden' name='baddr1' value={displayAddress.substring(0, 96)} />
                <input type='hidden' name='customerid' value={id} />
                <input type='hidden' name='dynamicMerchantName' value={this.props.selectedRestaurant.name.substring(0, 25)} />
                <input type='hidden' name='chargetotal' value={currentTotal.toFixed(2)} />
                <input type='hidden' name='currency' value={this.transformCurrencyIntoCode(currency)} />
                {
                    isIntegration ? 
                    <input type='hidden' name='parentUri' value={this.state.parentURI} /> : null
                }
                {/* OPTIONAL - but MANDATORY when on MOBILE (add logic later)*/}
                {
                    window.innerWidth <= 865 ? <input type='hidden' name='mobileMode' value={true} /> : null
                }
            </form>
        );
    }

    render() {
        const isMobile = window.innerWidth <= 865;
        const { primaryFontColor } = this.props.customerThemes.selectedTheme;
        const isArabic = this.props.language === ARABIC;

        return (
            <Col xs={12} sm={12} md={8} style={{ margin: 'auto', paddingBottom: 10, padding: isMobile ? 0 : 'auto' }}>
                {this.renderForm()}
                <label
                    htmlFor='isSaveCardAllowed'
                    hidden={this.props.payment.selectedPaymentType.id !== 1}
                    className='marketingCheckbox-label unselectable'
                    style={{
                        minWidth: '90%',
                        margin: isArabic ? '5px 22px auto auto' : '5px auto auto 22px',
                        color: primaryFontColor,
                        fontSize: 16,
                        lineHeight: '33px',
                        cursor: this.props.payment.selectedPaymentCard.paymentCardId ? 'default' : 'pointer'
                        }
                    }
                >
                    <span style={isArabic ? { marginRight: 10 } : { marginLeft: 10 }}>{i18n.t('screens:checkoutScreen.saveCardMsg')}</span>
                    <input
                        type='checkbox' name='isSaveCardAllowed' id='isSaveCardAllowed' style={{ marginTop: 5 }}
                        checked={this.state.saveCard}
                        onChange={this.changeState}
                        disabled={this.props.payment.selectedPaymentCard.paymentCardId} />
                    <span className='marketingCheckbox-custom'></span>
                </label>
                <p
                    hidden={this.props.payment.selectedPaymentType.id !== 1}
                    style={{
                        fontSize: 13,
                        color: primaryFontColor,
                        marginBottom: 0,
                        marginTop: 4
                    }}>*{i18n.t('screens:stripeForm.authipayPaymentWarning')}</p>
            </Col>
        );
    }
}

export default AuthipayForm;