export const LOADING = 'loading';
export const LOADING_NO_BACKGROUND = 'loading_no_background';

export const SET_CONNECTION_STATUS = 'set_connection_status_online';

// General Alert message
export const SHOW_ERROR_DIALOG = 'show_error_dialog';
export const CLEAR_ERROR_DIALOG = 'clear_error_dialog';

export const SHOW_SUCCESS_ALERT_MESSAGE = 'show_success_message_alert';
export const CLEAR_SUCCESS_ALERT_MESSAGE = 'clear_success_message_alert';
export const SHOW_ERROR_ALERT_MESSAGE = 'show_error_alert_message';
export const CLEAR_ERROR_ALERT_MESSAGE = 'clear_error_alert_message';
export const RESET_ALERTS_STATE = 'reset_alerts_state';

// Type of Application SPA or PWA
export const RUNNING_PWA = 'running_pwa';

//Login
export const LOGIN_SUCCESS = 'login_success';
export const SAVE_CUSTOMER_PHONE = 'save_customer_phone';
export const UPDATE_CUSTOMER_SUCCESS = 'update_customer_success';
export const SAVE_CUSTOMER_ID = 'save_customer_id';
export const LOAD_CUSTOMER_FROM_LOCAL_STORAGE = 'load_customer_from_local_storage';
export const SAVE_CUSTOMER_DELIVERY_ADDRESS = 'save_customer_delivery_Address';
export const LOG_OUT_CUSTOMER = 'log_out_customer';
export const SAVE_CC_PREFIX = 'save_cc_prefix';
export const SAVE_PROFILE_BACK_BUTTON_PATH = 'save_profile_back_button_path';
export const OPEN_START_ORDER_SCREEN = 'open_start_order_screen';
export const CLOSE_START_ORDER_SCREEN = 'close_start_order_screen';
export const SHOW_ERROR_MSG = 'show_error_msg';
export const RESET_PHONE_NUMBER_ERR_MSG = 'reset_phone_number_err_msg';
export const IS_LOGIN_CONFIRMATION = 'is_login_confirmation';
export const RESET_LOGIN_CONFIRMATION = 'reset_login_confirmation';
export const IS_FORGOTTEN_EMAIL_CONFIRMATION = 'is_forgotten_email_confirmation';
export const RESET_FORGOTTEN_EMAIL_CONFIRMATION = 'reset_forgotten_email_confirmation';
export const REGISTER_SUCCESS = 'register_success';
export const RESEND_SUCCESS_MESSAGE = 'show_resend_success_message';
export const SAVE_CUSTOMER_EMAIL = 'save_customer_email';
export const GET_CAPTCHA_SUCCESS = 'get_captcha_success';

export const GET_ORDER_TYPES_SUCCESS = 'get_order_types_success';
export const CLEAR_ORDER_TYPES = 'clear_order_types';
export const SELECT_ORDER_TYPE = 'select_order_type';
export const GET_LANGUAGE_SUCCESS = 'get_language_success';
export const GET_SELECTED_LANGUAGE_SUCCESS = 'get_selected_language_success';
export const IS_DEFAULT_LANGUAGE = 'is_default_language';

export const SELECT_BRAND_ID_SUCCESS = 'select_brand_id_success';
export const SELECT_BRAND_ID_FAILED = 'select_brand_id_failed';
export const GET_BRAND_DATA_SUCCESS = 'get_brand_data_success';
export const GET_BRAND_DATA_FROM_RESTAURANT_SUCCESS = 'get_brand_data_from_restaurant_success';
export const GET_BRAND_NAME = 'get_brand_name';

export const LOAD_RESTAURANTS_IN_ZONE_SUCCESS = 'load_restaurants_in_zone_success';
export const SAVE_SELECTED_RESTAURANT = 'save_selected_restaurant_success';
export const SAVE_RESTAURANT_SERVICE_FEE_PREVIEW_MODE = 'save_selected_restaurant_service_fee_preview_mode';
export const SAVE_SELECTED_RESTAURANT_TOPPING_SUBSTITUTION_FLAG_PREVIEW_MODE = 'save_selected_restaurant_toppings_substitution_preview_mode';
export const SAVE_SELECTED_RESTAURANT_PREVIEW_MODE = 'save_selected_restaurant_preview_mode';
export const SAVE_RESTAURANT_WORKING_TIME = 'save_restaurant_working_Time';
export const SAVE_RESTAURANT_MENUS = 'save_restaurant_menus';
export const SELECT_RESTAURANT_MENU_ID = 'select_restaurant_menu_id';
export const SAVE_RESTAURANT_CATEGORIES = 'save_restaurant_categories';
export const SAVE_RESTAURANT_PRODUCTS = 'save_restaurant_products';
export const SELECTED_MENU_ARRAY_INDEX = 'selected_menu_array_index';

export const GET_CUSTOMER_SAVED_RESTAURANTS_SUCCESS = 'get_customer_saved_restaurants_success';
export const RESET_CUSTOMER_SAVED_RESTAURANTS_SUCCESS = 'reset_customer_saved_restaurants_success';

export const GET_CUSTOMER_SAVED_PAYMENT_CARDS_SUCCESS = 'get_customer_saved_payment_cards_success';


export const GET_CUSTOMER_SAVED_ADDRESSES_SUCCESS = 'get_customer_saved_addresses_success';
export const RESET_CUSTOMER_SAVED_ADDRESSES_SUCCESS = 'reset_customer_saved_аддрессес_success';

export const GET_ESTIMATE_ORDER_TIME_SUCCESS = 'get_estimate_order_time_success';
export const GET_TEMPORARY_ESTIMATE_ORDER_TIME_SUCCESS = 'get_temporary_estimate_order_time_success';
export const CLEAR_TEMPORARY_ESTIMATE_ORDER_TIME_SUCCESS = 'clear_temporary_estimate_order_time_success';
export const SAVE_ORDER_TIME_DETAILS = 'save_order_time_details';
export const SAVE_ORDER_TIME_TAX_FLAG_PREVIEW_MODE = 'save_order_time_tax_flag_preview_mode';
export const SAVE_AVAILABLE_ORDERED_PRODUCTS = 'save_available_ordered_products';
export const TOGGLE_UNAVAILABLE_PRODUCT_SCREEN = 'toggle_unavailable_product_screen';

export const GET_USER_ADDRESS_DELIVERY_ZONE = 'get_user_address_delivery_zone';

export const CLEAR_ORDER_WIZARD = 'clear_order_wizard';

export const GET_CUSTOMER_CAR = 'get_customer_car';

// Shopping cart
export const ADD_OR_SUBSTRACT_QTY_TO_CART = 'add_or_substract_qty_to_cart';
export const REMOVE_ITEM_FROM_CART = 'remove_item_from_cart';
export const INCREASE_OR_DECREASE_TIP = 'increase_or_decrease_tip';
export const ADD_PRODUCT_TO_CART = 'add_product_to_cart';
export const SAVE_GENERAL_INSTRUCTIONS = 'save_general_instructions';
export const DISPATCH_PROMO_CODE = 'dispatch_promo_code';
export const DISPATCH_SERVICE_FEE = 'dispatch_service_fee';
export const INCREASE_OR_DECREASE_PROMO_SERVICE_CALLS_COUNT = 'increase_or_decrease_promo_service_calls_count';
export const PRODUCTS_QTY = 'products_qty';

// Apply promotions in Shopping cart AND PROMO PARTNERS
export const APPLY_PROMOTION_SUCCESS = 'apply_promotion_success';
export const SHOW_ERROR_MESSAGE = 'show_error_message';
export const GET_PROMOTION_PARTNER = 'get_promotion_partner';
export const TOGGLE_PROMO_PARTNER_MODAL = 'toggle_promo_partner_modal';
export const GET_MEMBERSHIP_DETAILS = 'get_membership_details';
export const RESET_COMO_ERROR_RESPONSE = 'reset_como_error_response';
export const RESET_COMO_ERRORS_ONLY = 'reset_como_errors_only';
export const USER_LOGGED_IN_PARTNER = 'user_logged_in_partner';
export const SET_TRANSACTIONID_AND_OPEN_TIME = 'set_transactionid_and_open_time';
export const GET_BENEFITS_RESPONSE = 'get_benefits_response';
export const SET_APPLIED_PROMOTIONS = 'set_applied_promotions';
export const SET_PARTNER_DISCOUNT_TOTAL = 'set_partner_discount_total';
export const SAVE_PAYMENT_RESPONSE = 'save_payment_response';
export const UPDATE_MEMBER_POINT_BALANCE = 'update_member_point_balance';
export const CANCEL_PAYMENT_REIMBURSE_POINTS = 'cancel_payment_reimburse_points';
export const TRIGGER_USER_REGISTRATION = 'trigger_user_registration';
export const SET_USER_ANSWER = 'set_user_answer';
export const VOID_PURCHASE = 'void_purchase';
export const GET_MEMBERSHIP_DETAILS_IN_PROGRESS = 'get_membership_details_in_progress';
export const APPLY_PROMO_CODE = 'apply_promo_code';
export const RESET_OLD_DISCOUNT_FLAG = 'reset_old_discount_flag';
export const IS_REDIRECT_FROM_COMO = 'is_redirect_from_como';
export const IS_RESTARTED_ORDER_FROM_COMO_USER = 'is_restarted_order_from_como_user';
export const COMO_TEMPORARY_TOKEN = 'como_temporary_token';

export const SELECTED_PRODUCT = 'selected_product';
export const SELECTED_PRODUCT_EDIT = 'selected_product_edit';

// IFRAMES
export const CLEAR_CURRENT_PATH_AFTER_USE = 'clear_current_path_after_use';
export const SAVE_CURRENT_PATH_TO_STORE = 'save_current_path_to_store';

//PWA 
export const ADD_PRODUCT_TO_OPENED_PRODUCTS = 'add_product_to_opened_products';

export const LOAD_PAYMENT_TYPES_SUCCESS = 'load_payment_types_success';
export const SELECT_PAYMENT_TYPE = 'select_payment_type';
export const SELECT_PAYMENT_CARD = 'select_payment_card';
export const CLEAR_SELECTED_PAYMENT_CARD = 'clear_selected_payment_card';
export const CLEAR_SHOPPING_CART = 'clear_shopping_cart';
export const CLEAR_PAYMENT_REDUCER = 'clear_payment_reducer';
export const CARD_INPUT_VALIDATION = 'card_input_validation';
export const CLICK_APPLE_GOOGLE_PAY = 'click_apple_google_pay';

export const OPEN_SENDING_ORDER_MODAL = 'open_sending_order_modal';
export const CLOSE_SENDING_ORDER_MODAL = 'close_sending_order_modal';
export const SEND_RESPONSE_TO_ORDER_MODAL = 'send_response_to_order_modal';
export const CLOSE_SENDING_MODAL = 'close_sending_modal';

export const OPEN_CHANGE_ORDER_TIME_SCREEN = 'open_change_order_time_screen';
export const CLOSE_CHANGE_ORDER_TIME_SCREEN = 'close_change_order_time_screen';

export const SAVE_START_ORDER_TIME = 'save_start_order_time';
export const CLEAR_STATE = 'clear_state';
export const CLEAR_APP_STATE = 'clear_app_state';


export const SAVE_ESTIMATED_ORDER_TIME = 'save_estimated_order_time';
export const LOAD_ORDER_HISTORY_SUCCESS = 'load_order_history_success';

export const LOAD_MARKETING_PREFERENCES_SUCCESS = 'load_marketing_preferences_success';

// Available Discounts
export const LOAD_AVAILABLE_DISCOUNTS_SUCCESS = 'load_available_discounts_success';

// Loyalty
export const LOAD_LOYALTY_SUCCESS = 'load_loyalty_success';
export const GET_ALL_LOYALTY_CODES_SUCCESS = 'get_all_loyalty_codes_success';
export const GET_AUTO_APPLY_LOYALTY = 'get_auto_apply_loyalty';
export const GET_OLDEST_ACTIVE_LOYALTY = 'get_oldest_active_loyalty';

//Preview Mode
export const SAVE_PREVIEW_MODE_VALUE = 'save_preview_mode_value';

// OrderHistory addToCart
export const LOAD_ORDER_PRODUCTS_SUCCESS = 'load_order_products_success';

// Customer-Frontend Themes 
export const GET_CURRENT_THEME_SUCCESS = 'get_current_theme_success';

// PWA MainScreen map zoom reducer
export const SAVE_MAP_ZOOM = 'save_map_zoom';

//PWA Version control actions
export const VERSION_CONTROL = 'version_control';

//Time warning actions
export const ALLOW_TIME_WARNING = 'allow_time_warning';
export const DISABLE_TIME_WARNING = 'disable_time_warning';
export const CHANGE_WANTED_TIME = 'change_wanted_time';
export const SAVE_COOK_TIME = 'save_cook_time';

// UPSELL
export const GET_UPSELL_PRODUCTS_SUCCESS = 'get_upsell_products_success';

//Menu description messages
export const GET_SERVICE_MESSAGES = 'get_service_messages';
export const CLEAR_SERVICE_MESSAGES = 'clear_service_messages';

// Payment providers
export const GET_ALL_PAYMENT_PROVIDERS_SUCCESS = 'get_all_payment_providers_success';
export const SAVE_TRANSACTION_ID = 'save_transaction_id';
export const MOLLIE_RESPONSE_STATUS = 'mollie_response_status';
export const AUTHIPAY_DATA = 'authipay_data';
export const AUTHIPAY_RESPONSE_STATUS = 'authipay_response_status';
export const WORLDPAY_DATA = 'worldpay_data';
export const CLEAR_WORLDPAY_DATA = 'clear_worldpay_data';
export const BAMBORA_DATA = 'bambora_data';
export const CLEAR_BAMBORA_DATA = 'clear_bambora_data';
export const MONERIS_DATA = 'moneris_data';
export const CLEAR_MONERIS_DATA = 'clear_moneris_data';
export const CLEAR_TBK_DATA = 'clear_tbk_data';
export const TBK_USER_DATA = 'tbk_user_data';
export const CONFIRM_ENROLLMENT_ERR_MSG = 'tbk_confirm_enrollment_err_msg';
//Firebase services
export const REGISTER_APP_DEVICE = 'register_app_device';

// InStore Ordering
// export const GET_RESTAURANT_DATA_FOR_IN_STORE_SUCCESS = 'get_restaurant_data_for_in_store_success';
export const SAVE_RESTAURANT_DATA_FOR_IN_STORE = 'save_restaurant_data_for_in_store';
export const SAVE_IN_STORE_WORKING_DAY = 'save_in_store_working_day';
export const SAVE_IN_STORE_BRAND_IMGS = 'save_in_store_brand_imgs';
export const SAVE_IN_STORE_BRAND_DATA = 'save_in_store_brand_data';
export const SAVE_IN_STORE_INFO = 'save_in_store_info';
export const ERROR_RESPONSE = 'error_response';
export const ERROR_RESPONSE_RESET = 'error_response_reset';

// StoreLocalStorage types
export const SAVE_CAR_DETAILS = 'save_car_details';
export const SAVE_FIRST_STAMP = 'save_first_stamp';
export const TOGGLE_MENU_ORDER_HISTORY_SCREEN = 'toggle_menu_order_history_screen';
export const CHANGE_APPLE_GOOGLE_PAY_AVAILABILITY = 'change_apple_google_pay_availability';
export const SAVE_NAVIGATION_TYPE = 'save_navigation_type';
export const CHANGE_AUTHIPAY_BACK_BUTTON_FLAG = 'change_authipay_back_button_flag';
export const SAVE_BRANDID_TO_STORE_LOCALSTORAGE = 'save_brandid_to_store_localstorage';
export const SAVE_WORKING_REST_COUNT = 'save_working_rest_count';
export const CHANGE_SIGN_IN_PRESSED = 'change_sign_in_pressed';
export const CHANGE_CODE_MANUALLY_SET = 'change_code_manually_set';
export const CLEAR_STORE_LOCALSTORAGE_STATE = 'clear_store_localstorage_state';
export const TOGGLE_SEARCH_PRODUCT_FOCUS = 'toggle_search_product_focus';

// Toggle Modals
export const TOGGLE_PROMOTIONS_MODAL = 'toggle_promotions_modal';
export const TOGGLE_SHOPPING_CART_MODAL = 'toggle_shopping_cart_modal';
export const CLEAR_CART_AND_PROMO_MODALS = 'clear_cart_and_promo_modals';

// Givex
export const APPLY_GIFTCARD_SUCCESS = 'apply_giftcard_success';
export const CLEAR_GIFT_CARDS_STATE = 'clear_gift_cards_state';
export const CLEAR_GIFT_CARDS_TOTAL_DISCOUNT = 'clear_gift_cards_total_disacount';
export const SHOW_ERROR_GIFT_CARDS_MESSAGE = 'show_error_gift_cards_message';