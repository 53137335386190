import axios from '../../../../utils/AxiosUtils';

import {
    GET_MEMBERSHIP_DETAILS,
    TOGGLE_PROMO_PARTNER_MODAL,
    LOADING_NO_BACKGROUND,
    RESET_COMO_ERROR_RESPONSE,
    RESET_COMO_ERRORS_ONLY,
    SET_TRANSACTIONID_AND_OPEN_TIME,
    GET_BENEFITS_RESPONSE,
    SAVE_PAYMENT_RESPONSE,
    SET_PARTNER_DISCOUNT_TOTAL,
    SET_APPLIED_PROMOTIONS,
    UPDATE_MEMBER_POINT_BALANCE,
    CANCEL_PAYMENT_REIMBURSE_POINTS,
    ADD_OR_SUBSTRACT_QTY_TO_CART,
    TRIGGER_USER_REGISTRATION,
    VOID_PURCHASE,
    GET_MEMBERSHIP_DETAILS_IN_PROGRESS,
    SHOW_ERROR_MESSAGE,
    APPLY_PROMO_CODE,
    RESET_OLD_DISCOUNT_FLAG,
    SET_USER_ANSWER,
    GET_CURRENT_THEME_SUCCESS,
    GET_ORDER_TYPES_SUCCESS,
    GET_BRAND_DATA_SUCCESS,
    CLEAR_ORDER_TYPES,
    SELECT_BRAND_ID_FAILED,
    LOGIN_SUCCESS,
    OPEN_START_ORDER_SCREEN,
    IS_REDIRECT_FROM_COMO,
    IS_RESTARTED_ORDER_FROM_COMO_USER,
    COMO_TEMPORARY_TOKEN
} from '../../../types';
import {
    updateShoppingCartState,
    openChangeOrderTimeScreen,
    clearState,
    isUserLoggedInPartner,
    saveCustomerToLocalStorage
} from '../../../index';
import PromotionPartnerUtils from '../../../../utils/promotions/PromotionPartnerUtils';
import { matchThemes } from '../../../../utils/ThemesSelector';
import { push } from "connected-react-router";

export const getComoMemberDetails = (onSuccess) => {
    return (dispatch, getState) => {

        dispatch({ type: LOADING_NO_BACKGROUND, payload: true });
        dispatch({ type: GET_MEMBERSHIP_DETAILS_IN_PROGRESS, payload: true });

        const customerId = getState().customer.id;
        const restaurantId = getState().selectedRestaurant.restaurantId;

        const url = `/promotion-partner/como/${restaurantId}/get-member-details-2?customerId=${customerId}`;

        let request = PromotionPartnerUtils.transformDataIntoPartnerStructure(getState().promotionPartners, { getState });
        request.customer = {};
        axios(getState).post(url, request)
        .then(res => {
            
                if (res.data.status === 'error' && res.data.errors[0].code === '4030000') {

                    dispatch({ type: SET_USER_ANSWER, payload: 'NO' });
                }

                dispatch({ type: GET_MEMBERSHIP_DETAILS, payload: res.data });
                dispatch({ type: LOADING_NO_BACKGROUND, payload: false });

                if (res.data.status !== 'error' && onSuccess) {
                    
                    if(res.data.membership.status === 'Active') {
                        dispatch(isUserLoggedInPartner(true));
                        dispatch(getComoBenefits());
                    }

                    if (getState().como.membership && getState().promotionPartners.isPromoPartnerModalOpen) {
                        dispatch({ type: TOGGLE_PROMO_PARTNER_MODAL });
                    }
                }
                dispatch({ type: GET_MEMBERSHIP_DETAILS_IN_PROGRESS, payload: false });
            })
            .catch(_ => {

                dispatch({ type: GET_MEMBERSHIP_DETAILS_IN_PROGRESS, payload: false });
                dispatch({ type: LOADING_NO_BACKGROUND, payload: false });
            });
    }
}

export const registerToComo = () => {
    return (dispatch, getState) => {

        dispatch({ type: LOADING_NO_BACKGROUND, payload: true });

        const customerId = getState().customer.id;
        const restaurantId = getState().selectedRestaurant.restaurantId;

        const url = `/promotion-partner/como/${restaurantId}/register?customerId=${customerId}`;

        axios(getState).get(url)
            .then(_ => {

                dispatch({ type: TRIGGER_USER_REGISTRATION });
                // dispatch({ type: LOADING_NO_BACKGROUND, payload: false });
                dispatch(resetErrorsOnly());
                setTimeout(() => {
                    dispatch(getComoMemberDetails(true));
                }, 3000)   
            })
            .catch(_ => {

                dispatch({ type: LOADING_NO_BACKGROUND, payload: false });
            });
    };
};

export const resetComoReducer = () => {
    return (dispatch) => {

        dispatch({ type: TOGGLE_PROMO_PARTNER_MODAL });
        dispatch({ type: RESET_COMO_ERROR_RESPONSE });
    };
};

export const getComoBenefits = (promotions, isPromoCode) => {
    return (dispatch, getState) => {

        dispatch({ type: LOADING_NO_BACKGROUND, payload: true });

        const customerId = getState().customer.id;
        const restaurantId = getState().selectedRestaurant.restaurantId;

        const url = `/promotion-partner/como/${restaurantId}/get-benefits-2?customerId=${customerId}`;

        const requestBody = PromotionPartnerUtils.transformDataIntoPartnerStructure(getState().promotionPartners, { getState, promotions });

        requestBody.singedInCustomer = getState().como.membership ? true : false;

        if (getState().shoppingCart.errorMessage) {

            dispatch({ type: SHOW_ERROR_MESSAGE, payload: null });
        }

        axios(getState).post(url, requestBody)
            .then(res => {

                const appliedPromotions = PromotionPartnerUtils.getAppliedPromotions(getState().promotionPartners, res.data);

                dispatch({ type: SET_APPLIED_PROMOTIONS, payload: appliedPromotions });
                dispatch({ type: GET_BENEFITS_RESPONSE, payload: res.data });
                // TODO review calculations here
                let params = {
                    shoppingCart: getState().shoppingCart,
                    appliedPromotions: appliedPromotions,
                    discountAmount: getState().como.userSavedData && getState().como.userSavedData.paymentResponse ? Math.abs(getState().como.userSavedData.paymentResponse.payments[0].amount) / 100 : 0,// change it to data from the response if needed
                }; // TODO check if params is necessary and if not make it take the data from store when no params are passed
                const discountTotal = PromotionPartnerUtils.calculateAllDiscounts(getState().promotionPartners, params);
                dispatch({ type: SET_PARTNER_DISCOUNT_TOTAL, payload: discountTotal });

                const nonRedeemablePromosWithCode = res.data.redeemAssets ? res.data.redeemAssets.find(asset => asset.nonRedeemableCause && asset.code) : null;

                if (nonRedeemablePromosWithCode) {
                    if (nonRedeemablePromosWithCode[0]) {
                        dispatch({ type: SHOW_ERROR_MESSAGE, payload: nonRedeemablePromosWithCode[0].nonRedeemableCause.message });
                    } else if (isPromoCode) {
                        dispatch({ type: SHOW_ERROR_MESSAGE, payload: nonRedeemablePromosWithCode.nonRedeemableCause.message });
                    }
                }

                setTimeout(function () {

                    let updatedShopingCart = updateShoppingCartState(getState, undefined);

                    dispatch({ type: ADD_OR_SUBSTRACT_QTY_TO_CART, payload: updatedShopingCart });
                }, 100);



                dispatch({ type: LOADING_NO_BACKGROUND, payload: false });
            })
            .catch(_ => {
                dispatch({ type: LOADING_NO_BACKGROUND, payload: false });
            });
    };
};

export const setTransactionIdAndOpenTime = (data) => {

    return (dispatch) => {
        dispatch({ type: SET_TRANSACTIONID_AND_OPEN_TIME, payload: data });
    };
};

export const applyComoCredits = (credits) => {

    return (dispatch, getState) => {

        dispatch({ type: LOADING_NO_BACKGROUND, payload: true });

        const customerId = getState().customer.id;
        const restaurantId = getState().selectedRestaurant.restaurantId;

        const url = `/promotion-partner/como/${restaurantId}/payment-2?customerId=${customerId}`;

        const requestBody = PromotionPartnerUtils.transformDataIntoPartnerStructure(getState().promotionPartners, { getState, credits });

        axios(getState).post(url, requestBody)
            .then(res => {

                let params = {
                    shoppingCart: getState().shoppingCart,
                    appliedPromotions: getState().promotionPartners.promoPartnerAppliedPromos,
                    discountAmount: credits,// change it to data from the response if needed
                };
                const discountTotal = PromotionPartnerUtils.calculateAllDiscounts(getState().promotionPartners, params);
                dispatch({ type: SET_PARTNER_DISCOUNT_TOTAL, payload: discountTotal });
                dispatch({ type: SAVE_PAYMENT_RESPONSE, payload: res.data });
                dispatch({ type: UPDATE_MEMBER_POINT_BALANCE, payload: res.data.updatedBalance });

                setTimeout(function () {
                    let updatedShopingCart = updateShoppingCartState(getState, undefined);
                    dispatch({ type: ADD_OR_SUBSTRACT_QTY_TO_CART, payload: updatedShopingCart })
                }, 100);

                dispatch({ type: LOADING_NO_BACKGROUND, payload: false });
            })
            .catch(_ => {

                dispatch({ type: LOADING_NO_BACKGROUND, payload: false });
            });
    };
};

export const cancelComoPayment = (onSuccess) => {

    return (dispatch, getState) => {

        dispatch({ type: LOADING_NO_BACKGROUND, payload: true });

        const customerId = getState().customer.id;
        const restaurantId = getState().selectedRestaurant.restaurantId;

        const url = `/promotion-partner/como/${restaurantId}/cancel-payment-2?customerId=${customerId}`;

        const requestBody = PromotionPartnerUtils.transformDataIntoPartnerStructure(getState().promotionPartners, { getState, cancelPayment: true });

        axios(getState).post(url, requestBody)
            .then(res => {

                dispatch({
                    type: CANCEL_PAYMENT_REIMBURSE_POINTS,
                    payload: {
                        balance: res.data.updatedBalance
                    }
                });
                dispatch({ type: SET_PARTNER_DISCOUNT_TOTAL, payload: 0 });

                setTimeout(function () {

                    let updatedShopingCart = updateShoppingCartState(getState, undefined);
                    dispatch({ type: ADD_OR_SUBSTRACT_QTY_TO_CART, payload: updatedShopingCart })
                }, 100);

                if (onSuccess) {

                    dispatch(onSuccess());
                }

                dispatch({ type: LOADING_NO_BACKGROUND, payload: false });
            })
            .catch(_ => {

                dispatch({ type: LOADING_NO_BACKGROUND, payload: false });
            });
    }
};

export const voidPurchase = (onSuccess) => {
    return (dispatch, getState) => {

        dispatch({ type: LOADING_NO_BACKGROUND, payload: true });

        const customerId = getState().customer.id;
        const restaurantId = getState().selectedRestaurant.restaurantId;

        const url = `/promotion-partner/como/${restaurantId}/void-purchase?customerId=${customerId}`;

        const requestBody = {
            purchase: {
                openTime: getState().como.userSavedData.openTime,
                transactionId: getState().como.userSavedData.transactionId,
                totalAmount: 0
            }
        };

        axios(getState).post(url, requestBody)
            .then(_ => {

                dispatch({ type: VOID_PURCHASE });
                dispatch({ type: LOADING_NO_BACKGROUND, payload: false });

                if (onSuccess) {
                    onSuccess();
                    dispatch({ type: SET_USER_ANSWER, payload: null });
                }
            })
            .catch(_ => {

                dispatch({ type: LOADING_NO_BACKGROUND, payload: false });
            });
    }
}

export const restartOrderComo = (changeTimeClicked, isApp, history, isRestartOrder) => {
    return (dispatch, getState) => {

        const { paymentResponse } = getState().como.userSavedData;
        const brandId = getState().brand.id;
        let path = `/start-order`;

        if (changeTimeClicked && isApp && paymentResponse) {

            path = `/brand/${brandId}/start-order/order-time`; // TODO check if this still works on apps -> the 'window' object
            const onSuccess = () => voidPurchase(() => history.push({ pathname: path, state: window.location.hash }))

            dispatch(cancelComoPayment(onSuccess));
        } else if (changeTimeClicked && isApp) {

            path = `/brand/${brandId}/start-order/order-time`;
            dispatch(voidPurchase(() => history.push({ pathname: path, state: window.location.hash })));
        } else if (isApp && paymentResponse) {

            path = `/brand/${brandId}/start-order/main-screen`;
            const onSuccess = () => voidPurchase(() => voidCallbackOnResetOrder(dispatch, path));

            dispatch(cancelComoPayment(onSuccess));
        } else if (isApp && isRestartOrder) {

            path = `/brand/${brandId}/start-order/main-screen`;
            const onSuccess = () => voidPurchase(() => history.push({ pathname: path, state: window.location.hash }));

            if (paymentResponse) {
                dispatch(cancelComoPayment(onSuccess));
            } else {
                dispatch(onSuccess());
            }

        } else if (paymentResponse) {

            const onSuccess = () => voidPurchase(() => voidCallbackOnResetOrder(dispatch, path));

            dispatch(cancelComoPayment(onSuccess));
        } else {

            dispatch(voidPurchase(() => voidCallbackOnResetOrder(dispatch, path)));
        }
    };
};

const voidCallbackOnResetOrder = (dispatch, path) => {
    dispatch(clearState());
    dispatch(openChangeOrderTimeScreen(path));
};

export const resetPromoCodeField = () => {
    return (dispatch) => {

        dispatch({ type: APPLY_PROMO_CODE, payload: null });
    };
};

export const resetOldDiscountFlag = () => {
    return (dispatch, getState) => {

        dispatch({ type: RESET_OLD_DISCOUNT_FLAG });
        let updatedShopingCart = updateShoppingCartState(getState, undefined);
        dispatch({ type: ADD_OR_SUBSTRACT_QTY_TO_CART, payload: updatedShopingCart });
    };
};

export const resetErrorsOnly = () => {
    return (dispatch) => {

        dispatch({ type: RESET_COMO_ERRORS_ONLY });
    };
};

export const comoMemberStartOrder = (id, temporaryToken, callback) => {
    return (dispatch, getState) => {

        dispatch({ type: LOADING_NO_BACKGROUND, payload: true });
        
        const url = `/restaurant-chain/${id}/como-start-order?temporaryToken=${temporaryToken}`;

        axios(getState).get(url)
            .then(response => comoMemberStartOrderSuccess(dispatch,response, getState, callback, temporaryToken))
            .catch(error => comoMemberStartOrderError(dispatch, error));
    }
}

const comoMemberStartOrderSuccess = (dispatch, response, getState, callback, temporaryToken ) => {

    dispatch({ type: LOGIN_SUCCESS, payload: response.data.data[0].customer });
        const customer = response.data.data[0].customer.data[0];
        const token = response.data.data[0].customer.token;
        saveCustomerToLocalStorage(customer, token);

    let customerThemes, currentTheme, serverTheme;
    customerThemes = getState().customerThemes;
    serverTheme = response.data.data[0].brandInfoDTO.theme;
    currentTheme = matchThemes(serverTheme, customerThemes);

    dispatch({ type: GET_CURRENT_THEME_SUCCESS, payload: currentTheme });
    dispatch({ type: GET_ORDER_TYPES_SUCCESS, payload: response.data.data[0].brandInfoDTO });
    dispatch({ type: GET_BRAND_DATA_SUCCESS, payload: response.data.data[0].brandInfoDTO });

    dispatch({ type: IS_REDIRECT_FROM_COMO, payload: true });
    dispatch( { type: COMO_TEMPORARY_TOKEN, payload: temporaryToken })

    dispatch({ type: LOADING_NO_BACKGROUND, payload: false });

    if (callback) {
        callback();
    }

    dispatch({ type: OPEN_START_ORDER_SCREEN, payload: `/start-order/order-type` })
    dispatch(push(`/start-order/order-type`));

};

const comoMemberStartOrderError = (dispatch, error) => {
    dispatch({ type: LOADING_NO_BACKGROUND, payload: false });
    dispatch({ type: CLEAR_ORDER_TYPES });
    dispatch({ type: SELECT_BRAND_ID_FAILED, payload: true });

};

export const isRestartedOrderFromComoUser = (boolean) => {
    return (dispatch) => {

        dispatch({ type: IS_RESTARTED_ORDER_FROM_COMO_USER, payload: boolean });
    }
}

export const saveComoTemporaryToken = (temporaryToken) => {
    return (dispatch) => {
        dispatch( { type: COMO_TEMPORARY_TOKEN, payload: temporaryToken })
    }
}

export const resetShowComoError = () => {
    return (dispatch) => {
        dispatch({ type: SHOW_ERROR_MESSAGE, payload: null });
    };
}